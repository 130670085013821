import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { AmmPaginatorTableComponent, PAGINATOR } from '../../amm-share/components/amm-paginator-table/amm-paginator-table.component';
import { AmmInputComponent } from '../../amm-share/components/amm-input/amm-input.component';
import { AmmMenuActionsComponent, MenuItem } from '../../amm-share/components/amm-menu-actions/amm-menu-actions.component';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { BusinessProfile } from '../../amm-share/models/sellerAccount.model';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { AmmDialogConfirmComponent } from '../../amm-share/components/amm-dialog-confirm/amm-dialog-confirm.component';
import { sellerAccountMock } from '../../amm-share/mockData/sellerAccount.mock';
import { APPCONSTANT, PAGE_SIZE, PJ_ACTION, PjActionEvent } from '../../amm-share/constants';
import { AmmBadgeComponent } from '../../amm-share/components/amm-badge/amm-badge.component';
import { MessageService } from 'primeng/api';
import { MerchantService } from '../../amm-share/services/merchant.service';
import { AmmMsgUtilService } from '../../amm-share/services';
import { getAccountStatusColor, getAccountStatusLabel } from '../../amm-share/utilities';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'amm-seller-accounts-page',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    OverlayPanelModule,
    AmmPaginatorTableComponent,
    AmmInputComponent,
    AmmMenuActionsComponent,
    DropdownModule,
    FormsModule,
    AmmBadgeComponent,

    ButtonModule,
  ],
  templateUrl: './seller-accounts-page.component.html',
  styleUrl: './seller-accounts-page.component.scss',
})
export class SellerAccountsPageComponent implements OnInit {

  private merchantService = inject(MerchantService);
  private messageService = inject(MessageService);
  private dialogService = inject(DialogService);
  private router = inject(Router);
  private msgUtilService = inject(AmmMsgUtilService);
  
  private activeActions: MenuItem[] = [
    { label: SELLER_ACTIONS.VIEW_DETAILS },
    { label: SELLER_ACTIONS.VIEW_STOREFRONT },
    { label: SELLER_ACTIONS.CONTACT_SELLER },
    { label: SELLER_ACTIONS.SUSPEND_OR_RESUME }
  ];
  private pendingActions: MenuItem[] = [
    { label: SELLER_ACTIONS.VIEW_DETAILS },
    { label: SELLER_ACTIONS.CONTACT_SELLER }
  ];
  private incompleteActions: MenuItem[] = [
    { label: SELLER_ACTIONS.VIEW_DETAILS },
    { label: SELLER_ACTIONS.CONTACT_SELLER },
    { label: SELLER_ACTIONS.SUSPEND_OR_RESUME }
  ];
  private _totalPages: number = APPCONSTANT.PAGE_DEFAULT_SIZE;
  private _totalRecords: number = APPCONSTANT.PAGE_DEFAULT_SIZE;
  private _currentPage: number = APPCONSTANT.PAGE_DEFAULT_SIZE;
  private _pageSize: number = PAGE_SIZE.MIN_SIZE;

  public currentInputSearchKey?: string;
  public isKeydownPressed: boolean = false;
  public searchKey?: string;
  public pageDescription?: string;
  public ALL_LABEL: string = 'All';
  public accountList: BusinessProfile[] = [];
  public accountStatusOptions = [
    { status: this.ALL_LABEL, label: 'Account status: ' + this.ALL_LABEL },
    { status: SELLER_ACCOUNT_STATUS_LABEL.ACTIVE, label: 'Account status: ' + SELLER_ACCOUNT_STATUS_LABEL.ACTIVE },
    { status: SELLER_ACCOUNT_STATUS_LABEL.PENDING, label: 'Account status: ' + SELLER_ACCOUNT_STATUS_LABEL.PENDING },
    { status: SELLER_ACCOUNT_STATUS_LABEL.INCOMPLETE, label: 'Account status: ' + SELLER_ACCOUNT_STATUS_LABEL.INCOMPLETE },
    { status: SELLER_ACCOUNT_STATUS_LABEL.SUSPENDED, label: 'Account status: ' + SELLER_ACCOUNT_STATUS_LABEL.SUSPENDED },
  ];
  public selectedAccountStatus = this.accountStatusOptions[0];

  public sellerTypeOptions = [
    { status: this.ALL_LABEL, label: 'Seller type: ' + this.ALL_LABEL },
    { status: BUSINESS_TYPE.BUSINESS, label: 'Seller type: ' + BUSINESS_TYPE.BUSINESS },
    { status: BUSINESS_TYPE.INDIVIDUAL, label: 'Seller type: ' + BUSINESS_TYPE.INDIVIDUAL },
  ];
  public selectedSellerType = this.sellerTypeOptions[0];
  public showFilters: boolean = false;


  ngOnInit(): void {
    this._initDataAndResetFilter();

  }

  private _initDataAndResetFilter(): void {
    this.isKeydownPressed = false;
    this.searchKey = undefined;
    this.currentInputSearchKey = undefined;
    this.showFilters = false;
    this.selectedAccountStatus = this.accountStatusOptions[0];
    this.selectedSellerType = this.sellerTypeOptions[0];

    this._currentPage = APPCONSTANT.PAGE_DEFAULT_SIZE;
    this._pageSize = PAGE_SIZE.MIN_SIZE;
    this.merchantService.fetchUserBusinessProfiles().subscribe(res => {
      this.accountList = res.dataList || [];
      this._totalRecords = res.totalRecords || 1;
      this._totalPages = res.totalPages || 1;
      this._currentPage = (res.currentPageIndex || 0) + 1;
      this.pageDescription = `${(this._currentPage - 1) * this._pageSize + 1} - ${(this._currentPage - 1) * this._pageSize + this.accountList.length} of ${this._totalRecords}`;
    });
  }

  private _fetchDataBySearchKey(pageIndex: number, pageSize: number, searchKey?: string): void {
    this.merchantService.searchSellerAccounts(searchKey, pageIndex, pageSize).subscribe(res => {
      this.accountList = res.dataList || [];
      this._totalRecords = res.totalRecords || 1;
      this._totalPages = res.totalPages || 1;
      this._currentPage = (res.currentPageIndex || 0) + 1;
      this.pageDescription = `${(this._currentPage - 1) * this._pageSize + 1} - ${(this._currentPage - 1) * this._pageSize + this.accountList.length} of ${this._totalRecords}`;
    });
  }

  private _fetchDataWithStatusOrBusinessType(pageIndex: number, pageSize: number, searchKey?: string): void {
    const  status = this.selectedAccountStatus.status === this.ALL_LABEL
        ? undefined
        : this.selectedAccountStatus.status;

    const  businessType = this.selectedSellerType.status === this.ALL_LABEL
        ? undefined
        : (this.selectedSellerType.status === BUSINESS_TYPE.BUSINESS ? 'PRIVATE' : BUSINESS_TYPE.INDIVIDUAL.toUpperCase());
    
    this.merchantService.searchSellerAccounts(searchKey, pageIndex - 1, pageSize, status, businessType).subscribe(res => {
      this.accountList = res.dataList || [];
      this._totalRecords = res.totalRecords || 1;
      this._totalPages = res.totalPages || 1;
      this._currentPage = (res.currentPageIndex || 0) + 1;
      this.pageDescription = `${(this._currentPage - 1) * this._pageSize + 1} - ${(this._currentPage - 1) * this._pageSize + this.accountList.length} of ${this._totalRecords}`;
    });
  }

  onFilterIconClick(): void {
    if ((this.selectedAccountStatus.status === this.ALL_LABEL) && (this.selectedSellerType.status === this.ALL_LABEL)) {
      this.showFilters = !this.showFilters;
      
    } else {
      this._initDataAndResetFilter();
    }

  }

  getSellerName(account: BusinessProfile): string {
    return account.seller?.nickName || '';
  }

  getStatusLabel(account: BusinessProfile): string {
    return getAccountStatusLabel(account);
  }

  getAccountType(account: BusinessProfile): string {
    return account.businessType === BUSINESS_TYPE.INDIVIDUAL.toUpperCase() ? BUSINESS_TYPE.INDIVIDUAL : BUSINESS_TYPE.BUSINESS;
  }

  getBadgeColor(account: BusinessProfile): string {
    return getAccountStatusColor(account);
  }

  getTableActions(account: BusinessProfile): MenuItem[] {
    if (this.getStatusLabel(account) === SELLER_ACCOUNT_STATUS_LABEL.PENDING) {
      return this.pendingActions;
    } else if (this.getStatusLabel(account) === SELLER_ACCOUNT_STATUS_LABEL.INCOMPLETE) {
      return this.incompleteActions;
    }

    return this.activeActions;
  }
  
  onMenuActionClick(action: MenuItem, account: BusinessProfile): void {
    switch (action.label) {
      case SELLER_ACTIONS.VIEW_DETAILS:
        this.router.navigate(['main', { outlets: { mainBody: ['seller-details', account.id || 0] } }]);
        break;
      case SELLER_ACTIONS.VIEW_STOREFRONT:
        if (account.store) {
          const href = `${environment.buyerSiteHost}/home/(mainBody:store/${account.store.code})`;
          window.location.href = href;
        }
        break;
      case SELLER_ACTIONS.CONTACT_SELLER:
        this.msgUtilService.contactStore(
          account.store?.ownerId,
          account.store?.code
        );
        break;
      case SELLER_ACTIONS.SUSPEND_OR_RESUME:
        this._suspendOrResumeSellerAction(account);
        break;
    }
  }

  private _suspendOrResumeSellerAction(account: BusinessProfile): void {
    const isSuspend = (this.getStatusLabel(account) === SELLER_ACCOUNT_STATUS_LABEL.ACTIVE 
      || this.getStatusLabel(account) === SELLER_ACCOUNT_STATUS_LABEL.INCOMPLETE);
    const actionDialogRef = this.dialogService.open(
      AmmDialogConfirmComponent,
      {
        data: {
          isSuspend: isSuspend,
          sellerInfo: account,
        },
        showHeader: false,
        closeOnEscape: true,
        width: '440px',
        style: {
          padding: '0px',
          width: '440px',
          border: 'none',
          'border-radius': '16px',
          'min-width': '440px',
          'min-height': '453px',
          'max-height': '440px',
        },
        contentStyle: {
          padding: '0px',
          'border-radius': '16px',
        },
      }
    );

    actionDialogRef.onClose.subscribe((res) => {
      if (res.btnLabel === 'confirm') {
        this.merchantService.suspendOrResumeBusinessProfile(account.id || 0, res.reason, isSuspend).subscribe(res => {
          if (res) {
            account.status = isSuspend ? SELLER_ACCOUNT_STATUS.INACTIVE : SELLER_ACCOUNT_STATUS.ACTIVE;
            
            this.messageService.add({
              severity: 'contrast',
              detail: isSuspend ? 'Account suspended' : 'Account resumed',
              key: 'page-operation-toast',
              life: 2000,
              data: {
                frontIconName: 'check',
              }
            });
          }
        });
      }

    });
  }

  setAccountStatus(event: DropdownChangeEvent): void {
    this.selectedAccountStatus = event.value;
    this._currentPage = APPCONSTANT.PAGE_DEFAULT_SIZE;
    this._pageSize = PAGE_SIZE.MIN_SIZE;
    this._fetchDataWithStatusOrBusinessType(this._currentPage, this._pageSize);

  }

  setSellerType(event: DropdownChangeEvent): void {
    this.selectedSellerType = event.value;
    this._currentPage = APPCONSTANT.PAGE_DEFAULT_SIZE;
    this._pageSize = PAGE_SIZE.MIN_SIZE;
    this._fetchDataWithStatusOrBusinessType(this._currentPage, this._pageSize);
  }


  getSearchEvent(action: PjActionEvent): void {
    switch (action.actionString) {
      case PJ_ACTION.SEARCH:
        if (action.para) {
          const inputSearchKey = action.para['searchKey'];
          this.currentInputSearchKey = inputSearchKey;

          if (inputSearchKey.length == 0) {
            this._initDataAndResetFilter();
          }
        }
        break;
      case PJ_ACTION.KEYDOWN:
        this.isKeydownPressed = true;
        if (action.para && action.para['searchKey'].length > 0) {
          this.searchKey = action.para['searchKey'];
          
          this._currentPage = APPCONSTANT.PAGE_DEFAULT_SIZE;
          this._pageSize = PAGE_SIZE.MIN_SIZE;
          if ((this.selectedAccountStatus.status === this.ALL_LABEL) && (this.selectedSellerType.status === this.ALL_LABEL)) {
            this._fetchDataBySearchKey(this._currentPage - 1, this._pageSize, this.searchKey);
          
          } else {
            this._fetchDataWithStatusOrBusinessType(this._currentPage, this._pageSize, this.searchKey);
          }

        }
        break;
      case PJ_ACTION.CLOSE:
        this._initDataAndResetFilter();

    }
  }

  isFirstPage(): boolean {
    return this._currentPage === APPCONSTANT.PAGE_DEFAULT_SIZE;
  }

  isLastPage(): boolean {
    return this._currentPage === this._totalPages;
  }

  paginatorAction(e: PjActionEvent): void {
    if (e.actionString === PAGINATOR.CHANGE_PAGE) {
      this._currentPage += e.para?.['indexChange'] || 1;
    } else if (e.actionString === PAGINATOR.ITEMS_PERPAGE) {
      this._pageSize = e.para?.['value'] || PAGE_SIZE.MIN_SIZE;
      this._currentPage = APPCONSTANT.PAGE_DEFAULT_SIZE;
    }

    if ((this.selectedAccountStatus.status === this.ALL_LABEL) && (this.selectedSellerType.status === this.ALL_LABEL)) {
      this._fetchDataBySearchKey(this._currentPage - 1, this._pageSize, this.searchKey);
      
    } else {
      this._fetchDataWithStatusOrBusinessType(this._currentPage, this._pageSize, this.searchKey);
    }
  }

}

export const SELLER_ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DRAFT: 'DRAFT'
}

export const SELLER_ACCOUNT_STATUS_LABEL = {
  ACTIVE: 'Active',
  PENDING: 'Pending approval',
  INCOMPLETE: 'Incomplete',
  SUSPENDED: 'Suspended'
}


export const BUSINESS_TYPE = {
  BUSINESS: 'Business',
  INDIVIDUAL: 'Individual'
}

export const SELLER_ACTIONS = {
  VIEW_DETAILS: 'View details',
  VIEW_STOREFRONT: 'View storefront',
  CONTACT_SELLER: 'Contact seller',
  SUSPEND_OR_RESUME: 'Suspend/Resume account'
}

export interface DropdownItem {
  status: string;
  label: string;
}